<template>
  <div class="container">
    <img src="./images/sample_form_header.jpg" width="100%" class="sample-header-image">
    <b-card-group deck>
      <b-card header="HUGGIES好奇裸感好動褲派樣包申請活動" header-tag="header">
        <b-card-text
          >謝謝您申請好奇裸感好動褲派樣包，請務必填寫完成表單資料，並確認收到LINE成功申請訊息</b-card-text
        >
      </b-card>
    </b-card-group>
    <b-card
      ><b-form-group
        label-cols="12"
        label-cols-lg="2"
        label-size="sm"
        label-for="name"
      >
        <div slot="label">會員姓名<span class="red">*</span></div>
        <b-form-input
          id="name"
          class="mb-2 mr-sm-2 mb-sm-0"
          v-model="form.name"
          placeholder="請輸入真實姓名"
          :state="v$.form.name.$invalid ? false : null"
        ></b-form-input>
        <b-form-invalid-feedback :state="!v$.form.name.$invalid">
          請輸入真實姓名
        </b-form-invalid-feedback>
      </b-form-group>
    </b-card>
    <b-card
      ><b-form-group
        label-cols="12"
        label-cols-lg="2"
        label-size="sm"
        label-for="telephone"
      >
        <div slot="label">聯絡電話<span class="red">*</span></div>
        <b-form-text
          >若手機號碼重新輸入，會員表單裡電話資料會同步修改
        </b-form-text>
        <b-form-input
          id="telephone"
          class="mb-2 mr-sm-2 mb-sm-0"
          v-model="form.telephone"
          placeholder="請輸入手機號碼"
          :state="v$.form.telephone.$invalid ? false : null"
        ></b-form-input>
        <b-form-invalid-feedback :state="!v$.form.telephone.$invalid">
          請輸入手機號碼
        </b-form-invalid-feedback>
      </b-form-group>
    </b-card>
    <b-card
      ><b-form-group
        label-cols="12"
        label-cols-lg="2"
        label-size="sm"
        label-for="address"
      >
        <div slot="label">收取派樣包地址<span class="red">*</span></div>

        <b-form-text
          >請填妥：郵遞區號/ 縣市 / 鄉鎮市區 / 路 巷 弄 號 / 樓 室
          【*若資料不完整或地址無法辨識，將無法完成寄件。】
        </b-form-text>
        <b-form-input
          id="address"
          class="mb-2 mr-sm-2 mb-sm-0"
          v-model="form.address"
          :state="v$.form.address.$invalid ? false : null"
          placeholder="請輸入文字"
        ></b-form-input>
        <b-form-invalid-feedback :state="!v$.form.address.$invalid">
          請輸入文字
        </b-form-invalid-feedback>
      </b-form-group>
    </b-card>
    <b-card
      ><b-form-group
        label-cols="12"
        label-cols-lg="2"
        label-size="sm"
        label-for="email"
      >
        <div slot="label">e-mail<span class="red">*</span></div>

        <b-form-input
          id="email"
          class="mb-2 mr-sm-2 mb-sm-0"
          v-model="form.email"
          placeholder="請輸入 email"
          :state="v$.form.email.$invalid ? false : null"
        ></b-form-input>
        <b-form-invalid-feedback :state="!v$.form.email.$invalid">
          請輸入 e-mail
        </b-form-invalid-feedback>
      </b-form-group>
    </b-card>
    <b-card
      ><b-form-group
        label-cols="12"
        label-cols-lg="2"
        label-size="sm"
        label-for="sample_size"
        :state="v$.form.sample_size.$invalid ? false : null"
      >
        <div slot="label">寶寶尿布尺寸<span class="red">*</span></div>

        <b-form-text
          >若兩個尺寸都已領取完畢，即表示申請已宣告結束，歡迎下次活動持續參與，謝謝您!
        </b-form-text>
        <b-form-radio-group
          v-model="form.sample_size"
          name="radio-options"
          :options="sizeOptions"
          :state="v$.form.sample_size.$invalid ? false : null"
          class="sample-radio"
          stacked
        ></b-form-radio-group>
      </b-form-group>
    </b-card>

    <div class="text-center">
      <b-button
        class="mb-2"
        style="background: #5c7580; color: #fff"
        size="lg"
        @click.prevent="handleSubmit"
        >送出</b-button
      >
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import { mapActions, mapGetters } from "vuex";

const EVENT_CODE = "huggie_2022_sample";
const EVENT_MODE = {
  ONCE: "once",
  UPDATE: "update",
  MULTIPLE: "multiple",
};

export default {
  setup: () => ({ v$: useVuelidate({ $lazy: true }) }),

  validations() {
    return {
      form: {
        name: {
          required,
        },
        telephone: {
          required,
        },
        address: {
          required,
        },
        email: {
          required,
          email,
        },
        sample_size: {
          required,
        },
      },
    };
  },

  data() {
    return {
      form: {
        name: "",
        telephone: "",
        address: "",
        email: "",
        sample_size: "",
      },
      sizeOptions: [
        {
          value: "M",
          text: "M",
          disabled: false,
        },
        {
          value: "L",
          text: "L",
          disabled: false,
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      me: "liffHuggies/me",
    }),
  },

  async created() {
    await this.checkEventMode();
    await this.checkAmount();

    if (this.me) {
      this.form = {
        ...this.form,
        name: this.me.extra?.parent_name,
        telephone: this.me.mobile,
        email: this.me.email,
      };
    }
  },

  methods: {
    ...mapActions({
      saveEvent: "liffHuggies/saveEvent",
      getEventMode: "liffHuggies/getEventMode",
      getEventFieldCount: "liffHuggies/getEventFieldCount",
      setLoading: "liffHuggies/setLoading",
    }),
    async handleSubmit() {
      const result = await this.v$.$validate();
      if (!result) {
        return;
      }

      this.setLoading(true);

      let checkAmount = await this.checkAmount();

      if (checkAmount === false) {
        return;
      }

      await this.saveEvent({
        form: this.form,
        event_code: EVENT_CODE,
      }).then((res) => {
        if (res.status === 200) {
          window.location.href = "https://line.me/R/ti/p/@huggiestw";
          // this.$router.push({ name: "LiffHuggiesSampleDone" });
        }
      });
    },

    async checkAmount() {
      const {
        data: {
          data: {
            config,
          },
        },
      } = await this.getEventMode({ event_code: EVENT_CODE });

      // 檢查活動數量
      if (config.quantity["M"] <= 0 && config.quantity["L"] <= 0) {
        alert("親愛的好奇爸媽您好！謝謝各位的參與，派樣已全數發送完畢，請持續關注好奇官方LINE 活動訊息！");
        this.$router.push({ name: "LiffHuggiesSamplePage", params: { org_code: this.$route.params.org_code }}).then(() => {
          this.setLoading(false);
        });
        return false;
      }

      const { data: { data: fieldCount } } = await this.getEventFieldCount({
        event_code: EVENT_CODE,
        field: 'sample_size',
        values: ["L", "M"],
      });

      if (fieldCount["M"] + fieldCount["L"] >= config.quantity["*"]) {
        alert("親愛的好奇爸媽您好！謝謝各位的參與，派樣已全數發送完畢，請持續關注好奇官方LINE 活動訊息！");
        this.$router.push({ name: "LiffHuggiesSamplePage" , params: { org_code: this.$route.params.org_code }}).then(() => {
          this.setLoading(false);
        });
        return false;
      }

      if (fieldCount["M"] >= config.quantity["M"]) {
        this.sizeOptions.find((option) => option.value === "M").disabled = true;
        this.sizeOptions.find((option) => option.value === "M").text = "M(已發送完畢)";
      }

      if (fieldCount["L"] >= config.quantity["L"]) {
        this.sizeOptions.find((option) => option.value === "L").disabled = true;
        this.sizeOptions.find((option) => option.value === "L").text = "L(已發送完畢)";
      }

      if (
        this.sizeOptions.find((option) => option.value === "M").disabled &&
        this.sizeOptions.find((option) => option.value === "L").disabled
      ) {
        alert("親愛的好奇爸媽您好！謝謝各位的參與，派樣已全數發送完畢，請持續關注好奇官方LINE 活動訊息！");
        this.$router.push({ name: "LiffHuggiesSamplePage" , params: { org_code: this.$route.params.org_code }}).then(() => {
          this.setLoading(false);
        });
        return false;
      }

      return true;
    },

    async checkEventMode() {
      const {
        data: {
          data: {
            config,
            last_record,
          },
        },
      } = await this.getEventMode({ event_code: EVENT_CODE });

      if (config.mode === EVENT_MODE.ONCE && last_record) {
        alert("親愛的好奇爸媽您好!您已領取過好奇裸感好動褲派樣包，謝謝您的持續參與!請繼續支持好奇紙尿褲!");
        this.$router.push({ name: "LiffHuggiesSampleDone", params: { org_code: this.$route.params.org_code }}).then(() => {
          this.setLoading(false);
        });
        return false;
      }
      if (config.mode === EVENT_MODE.UPDATE && last_record) {
        this.form = last_record;
      }
    },
  },
};
</script>

<style lang="scss">
.container {
  padding: 20px;
}
.red {
  color: #dc3545;
  font-weight: normal;
}
.card {
  border-radius: 10px;
  margin-bottom: 15px;
}
.card .card-header {
  background: #5c7580;
  color: #fff;
  padding: 20px;
  font-size: 22px;
  text-align: left;
  border-radius: 10px 10px 0 0;
  border-bottom: 0;
}
.card .card-body {
  padding: 16px 20px;
  text-align: left;
  font-size: 14px;
  color: #9e9fa1;

  border-radius: 0 0 10px 10px;
}

.card-deck .card {
  margin-bottom: 15px;
}
.card-deck .card .card-body {
  border: solid 1px #5c7580;
}

.form-group .col-form-label {
  color: #666;
  font-weight: bold;
  font-size: 16px;
}

.form-text {
  font-size: 14px;
  margin-bottom: 14px;
}
.form-control {
  font-size: 14px;
}

.sample-radio {
  .custom-radio {
    margin-bottom: 10px;
  }
  .custom-radio label {
    line-height: 1.5;
  }
}

.sample-header-image {
  margin-bottom: 20px;
}
</style>
